<template>
  <div class="container round px-0">
    <div class="page-header mb-1">
      <strong>ออกผลรางวัล</strong>
    </div>
    <div v-if="roundId" class="card">
      <RoundCardHeader />
      <ResultSingle v-if="marketType==='single'" :round="round" @reload="getRound" />
      <ResultLottoset v-if="marketType==='lottoset'" :market="market" :round="round" @reload="getRound" />
    </div>
    <div v-else class="card">
      <div class="card-body">กรุณาเลือกงวดหวย</div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import LottoService from '@/services/lottoService'
import RoundCardHeader from './components/RoundCardHeader'
import _ from 'lodash'
import ResultSingle from './components/ResultSingle'
import ResultLottoset from './components/ResultLottoset'

export default {
  name: 'PayHalfNumbers',
  components: {
    RoundCardHeader,
    ResultSingle,
    ResultLottoset
  },
  data() {
    return {
      market: null,
      round: null
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket.round.roundId
    },
    marketType() {
      return this.market?.marketType
    }
  },
  watch: {
    roundId() {
      if(this.roundId) {
        this.getRound()
      }
    }
  },
  methods: {
    getRound() {
      LottoService.getRound(this.roundId)
      .then((response)=>{
        if(response.success) {
          this.market = response.data.market
          this.round = response.data.round
        }else{
          throw new Error(response?.message || JSON.stringify(response?.data))
        }
      })
      .catch((e)=>{
        Swal.fire({
          title: 'ผิดพลาด!',
          text: (e?.message || e?.data) || 'กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        .then(()=>{
          this.$router.push({name: 'MarketDashboard'})
        })
      })
    }
  },
  mounted() {
    this.getRound()
  }
}
</script>
